<template>
<v-app>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          Detail Kelompok Kelas
        </h5>
        <table class="table mt-8">
          <tr>
            <th class="pr-1" width="150">Nama</th>
            <td class="pl-0 pr-0" width="10">:</td>
            <td class="pl-2">
              <span class="">{{ detail.name }}</span>
            </td>
          </tr>
          <tr>
            <th class="pr-1" width="150">Tingkat</th>
            <th class="pl-0 pr-0" width="10">
              <span class="font-weight-bold">:</span>
            </th>
            <td class="pl-2">
              <span class="">{{ detail.grade_name }}</span>
            </td>
          </tr>
          <tr>
            <th class="pr-1" width="150">Prodi</th>
            <th class="pl-0 pr-0" width="10">:</th>
            <td class="pl-2">
              <span class="">{{ detail.major_name }}</span>
            </td>
          </tr>
          <tr>
            <th class="pr-1" width="150">Angkatan</th>
            <th class="pl-0 pr-0" width="10">:</th>
            <td class="pl-2">
              <span class="">{{ detail.school_year_name }}</span>
            </td>
          </tr>
          <tr>
            <th class="pr-1" width="150">Jenis Periode</th>
            <th class="pl-0 pr-0" width="10">:</th>
            <td class="pl-2">
              <span class="">{{ detail.periode_type_name }}</span>
            </td>
          </tr>
          <tr>
            <th class="pr-1" width="150">Jumlah Mahasiswa</th>
            <th class="pl-0 pr-0" width="10">:</th>
            <td class="pl-2">
              <span class="">{{ total_students }}</span>
            </td>
          </tr>
          <tr>
            <th class="pr-1" width="150">Dosen Wali</th>
            <th class="pl-0 pr-0" width="10">:</th>
            <td class="pl-2">
              <span class="">{{ detail.user_name }}</span>
            </td>
          </tr>
        </table>

        <b-tabs content-class="my-3" @input="inputTabs">
          <b-tab title="Daftar Mahasiswa"></b-tab>
          <!-- <b-tab title="Rekap Absen"></b-tab>
          <b-tab title="Rekap Nilai"></b-tab>
          <b-tab title="Grafik Kemajuan Mahasiswa"></b-tab> -->
        </b-tabs>

        <!-- Daftar Mahasiswa -->
        <template v-if="orderTabActive == 0">
            <div
            class="d-flex justify-content-between align-items-center my-3 px-8 mt-10"
          >
            <div>
              <div class="alert-icon d-inline">
                <span class="svg-icon svg-icon-lg">
                  <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
                </span>
              </div>
              <div class="alert-text d-inline">
                Daftar <b>Mahasiswa</b> di Kelompok Kelas {{ detail.name }} ini
              </div>
            </div>
            <div>
              <!-- <i
                class="fas fa-user-plus"
                @click="showModalUser(2)"
                v-b-tooltip.hover.top="'Tambah Peserta'"
                style="cursor: pointer"
              ></i> -->
              <b-button
                squared
                variant="primary"
                @click="showModalUser(2)"
                >Tambah</b-button
              >
            </div>
          </div>
            <b-table
              striped
              hover
              :items="students"
              :fields="fields"
              class="mt-3"
              responsive
              :class="'nowrap'"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'action' ? '25%' : '' }"
                />
              </template>
              <template #cell(participant_photo)="data">
                <div
                  class="
                    d-flex
                    justify-content-start
                    align-items-center
                    image-container
                  "
                >
                  <div class="image-input-wrapper" style="position: relative">
                    <img class="image" :src="data.item.participant_photo" />
                  </div>
                </div>
              </template>

              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.participant_id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
        </template>
        <!-- Rekap Absen -->
        <div :class="orderTabActive == 1 ? 'd-block' : 'd-none'">
            <v-tabs v-model="currentItem" fixed-tabs slider-color="white">
              <v-tab v-for="item in items" :key="item" :href="'#tab-' + item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <div :class="currentItem == 'tab-Absen Sekolah' ? 'd-block' : 'd-none'">
              <!-- filter -->
              <div class="row justify-content-end">
                <div class="col-md-3">
                  <b-form-select
                    v-model="filter.month"
                    :options="monthOption"
                    @input="filterByMonth"
                  ></b-form-select>
                </div>
                <div class="col-md-3" v-if="userData.role_id == 1">
                  <b-form-group
                    id="input-group-class-groups"
                    label-for="input-class-groups"
                  >
                    <treeselect
                      v-model="filter.class_group_id"
                      :multiple="false"
                      placeholder="Pilih Kelompok Kelas"
                      :options="class_groups"
                      @input="filterByClassGroup"
                    />
                  </b-form-group>
                </div>
                <div class="col-md-4" v-if="userData.role_id == 1">
                  <b-input-group class="mb-2">
                    <b-form-select
                      v-model="search"
                      :options="optionsName"
                      @input="filterByName"
                    ></b-form-select>
                    <b-input-group-append>
                      <button class="btn btn-danger" @click="filterReset">
                        Reset
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <b-tabs content-class="mt-3">
                <b-tab
                  title="Rekap Absen"
                >
                  <TablePresence />
                </b-tab>
                <b-tab title="Kalender" v-if="userData.role_id == 2">
                  <CalendarPresence />
                </b-tab>
              </b-tabs>
            </div>
            <div :class="currentItem == 'tab-Absen Ruang Kelas' ? 'd-block' : 'd-none'">
              <PresenceClassroom
                classroomId="9"
                purpose="class-groups"
                :participants="participants"
              />
            </div>
        </div>
        <!-- Rekap Nilai -->
        <div :class="orderTabActive == 2 ? 'd-block' : 'd-none'">
            <TableRecapValue purpose="class-group" />
        </div>

      </div>
    </div>
    <!-- <TablePresence /> -->
    <ModalUser
      purpose="modal-user"
      :route="`api/users/get-available-users-for-class-groups/${detail.id}`"
      :selectedItem="participant_id"
      :filterParticipant="filter"
      @chooseUser="setAddParticipant"
      @formOnSubmit="formOnSubmit"
      secondPurpose="addClassGroupMember"
    />
  </div>
</v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";
import TablePresence from "@/view/components/presence/Table.vue";
import CalendarPresence from "@/view/components/presence/Calendar.vue";
import TableRecapValue from "@/view/components/school-report-card/TableRecapValue";
import ModalUser from "@/view/components/general/ModalUser.vue";
import PresenceClassroom from "@/view/components/classroom/Presence";

export default {
  components: {
    TablePresence,
    CalendarPresence,
    TableRecapValue,
    ModalUser,
    PresenceClassroom
  },
  data() {
    return {
      currentItem: "tab-Absen Sekolah",
      items: ["Absen Sekolah", "Absen Ruang Kelas"],
      display: {
        participant_name: "",
      },
    //   filter: {
    //     role_id: "",
    //   },
      dataLoaded: false,
      classroom_id: "",
      // participants
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "participant_photo",
          label: "Foto",
        },
        {
          key: "participant_name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIS",
          sortable: true,
        },
        {
          key: "major_name",
          label: "Prodi",
          sortable: true,
        },
        {
          key: "phone",
          label: "Telepon",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      detail: [],
      students: [],
      teacher: {},
      total_students: [],
      participant_id: [],
      form: {
        class_group_id: "",
        user_id: "",
      },
    //   absen sekolah
    // search
      search: null,
      optionsName: [],
      optionsNameForm: {
        value: "",
        text: "",
      },
    filter: {
        role_id: "",
        school_year_id: "",
        now: new Date().toISOString().substr(0, 10),
        month:
          new Date().toISOString().substr(0, 4) +
          "-0" +
          (new Date().getMonth() + 1),
        start_periode: "",
        class_group_id: "",
        classroom_id: ""
      },
      class_groups: [],
      monthOption: [{ value: "-1", text: "Semua Bulan" }],
      monthOptionForm: {
        value: "",
        text: "",
      },
      months: [],
    //   absen ruang kelas
    participants: [],
    classrooms: [],
    //  other
      orderTabActive: 0,
      userData: getUser(),
    };
  },
  methods: {
    inputTabs(evt) {
      this.orderTabActive = evt;
    },
    async getClassGroupById() {
      let response = await module.getFullResponse(
        `api/class-group-users/class-groups/${this.$route.params.classGroupid}/${this.$route.params.teacherId}`
      );
      // If Data Not Found
      if (response == null) {
        // Redirect To List
      } else {
        this.detail = response.data.data;
        this.teacher = response.data.meta.teacher;
        this.total_students = response.data.meta.total_students;
        //("total_students", this.total_students);
        //("detail", this.detail);
        //("teacher", this.teacher);

        let a, b;
        b = this.perPage * (this.currentPage - 1) + 1;
        for (a = 0; a < response.data.meta.students.length; a++) {
          response.data.meta.students[a].number = b + a;
        }
        this.students = response.data.meta.students;
        //("students", this.students);
      }
    },
    showModalUser(role_id) {
      this.filter.role_id = role_id;
      this.$bvModal.show("modal-user");
    },
    async deleteData(id) {
      // // Delete Data
      // //   class-group-users/class-groups/{classGroupId}/users/{userId}/detach
      // let result = await module.delete(
      //   `api/class-group-users/class-groups/${this.detail.id}/users/${id}/detach`
      // );
      // // If Deleted
      // if (result) {
      //   this.getClassGroupById();
      // }
      this.getClassGroupById();
    },
    setAddParticipant(value) {
      if (this.participant_id.length == 0) {
        let clone = { ...value };
        this.participant_id.push(clone);
        //("participant_id", this.participant_id);
        this.displayParticipantName(this.participant_id);
      } else {
        let a;
        for (a = 0; a < this.participant_id.length; a++) {
          if (this.participant_id[a].id == value.id) {
            this.participant_id.splice(a, 1);
            if (this.participant_id.length == 0) {
              this.display.participant_name = "";
              break;
            } else if (this.participant_id.length > 0) {
              this.displayParticipantName(this.participant_id);
              break;
            }
            break;
          } else if (a == this.participant_id.length - 1) {
            let clone = { ...value };
            this.participant_id.push(clone);
            //("participant_id", this.participant_id);
            this.displayParticipantName(this.participant_id);
            break;
          }
        }
      }
    },
    displayParticipantName(participant) {
      if (participant.length == 0) {
        this.display.participant_name = "";
      } else {
        let str = "";
        participant.forEach(function (value, index) {
          str += value.name;
          if (index != participant.length - 1) {
            if (participant.length > 1) str += ", ";
          }
        });
        this.display.participant_name = str;
      }
    },
    resetParticipant() {
      this.participant_id.splice(0, this.participant_id.length);
      //("reset", this.participant_id);
      this.display.participant_name = "";
    },
    async formOnSubmit() {
      if (this.participant_id.length > 0) {
        SwalLoading.fire();
        let a;
        for (a = 0; a < this.participant_id.length; a++) {
          this.form.user_id = this.participant_id[a].id;
          this.form.class_group_id = this.detail.id;
          //   class-group-users/class-groups/{classGroupId}/users/{userId}/attach
          let response = await module.submitParticipant(
            this.form,
            `api/class-group-users/class-groups/${this.detail.id}/users/${this.form.user_id}/attach`
          );

          if (a == this.participant_id.length - 1) {
            this.getClassGroupById();
            SwalLoading.close();
            Swal.fire(
              response.success.title,
              response.success.message,
              "success"
            );
            this.display.participant_name = "";
            this.participant_id = [];
          }
        }
      } else {
        Swal.fire({
          title: "Gagal",
          text: "Anda belum memilih peserta",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.error.name = "Peserta harus diisi";
      }
    },
    // absen sekolah
    async getSChoolYearActive() {
      let response = await module.paginate("api/school-years/month");

      this.months = response.data[0].months;
 
      for (let a = 0; a < this.months.length; a++) {
        this.monthOptionForm.value =
          this.months[a].year + "-" + this.months[a].order_month_natural;
        if (this.months[a].order_month == "1") {
          this.monthOptionForm.text = "Januari " + this.months[a].year;
        }
        if (this.months[a].order_month == "2") {
          this.monthOptionForm.text = "Februari " + this.months[a].year;
        }
        if (this.months[a].order_month == "3") {
          this.monthOptionForm.text = "Maret " + this.months[a].year;
        }
        if (this.months[a].order_month == "4") {
          this.monthOptionForm.text = "April " + this.months[a].year;
        }
        if (this.months[a].order_month == "5") {
          this.monthOptionForm.text = "Mei " + this.months[a].year;
        }
        if (this.months[a].order_month == "6") {
          this.monthOptionForm.text = "Juni " + this.months[a].year;
        }
        if (this.months[a].order_month == "7") {
          this.monthOptionForm.text = "Juli " + this.months[a].year;
        }
        if (this.months[a].order_month == "8") {
          this.monthOptionForm.text = "Agustus " + this.months[a].year;
        }
        if (this.months[a].order_month == "9") {
          this.monthOptionForm.text = "September " + this.months[a].year;
        }
        if (this.months[a].order_month == "10") {
          this.monthOptionForm.text = "Oktober " + this.months[a].year;
        }
        if (this.months[a].order_month == "11") {
          this.monthOptionForm.text = "November " + this.months[a].year;
        }
        if (this.months[a].order_month == "12") {
          this.monthOptionForm.text = "Desember " + this.months[a].year;
        }
        let clone = { ...this.monthOptionForm };
        this.monthOption.push(clone);
      }
   
    },
    async getClassGroupOption() {
      let response = await module.setTreeSelect(
        `api/class-groups/available/my-class-groups/users/${getUser().id}`,
        `?now=${this.filter.now}`
      );
      //("classgroup", response);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.class_groups = response.data;
        this.class_groups.unshift({
          label: "Pilih Kelompok Kelas",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getMyStudent() {
      let filterParams = `&class_group_id=${this.filter.class_group_id}&role_id=2`;
      let response = await module.paginate(
        `api/users/my-students/${getUser().id}}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      this.users = response.data;
 
      for (let a = 0; a < this.users.length; a++) {
        this.optionsNameForm.text = this.users[a].name;
        this.optionsNameForm.value = this.users[a].name;

        let clone = { ...this.optionsNameForm };
        this.optionsName.push(clone);

        if (a + 1 == this.users.length) {
          this.optionsName.unshift({
            text: "Pilih Nama Mahasiswa",
            value: null,
            disabled: true,
          });
        }
      }
    },

    filterByName() {
      this.$root.$emit("filterPresenceByName", this.search);
    },
    async filterByClassGroup(evt) {
      //(evt);
      if (!evt) {
        this.filter.class_group_id = await "";
        this.$root.$emit("filterByClassGroup", this.filter);
        this.optionsName = []
        this.getMyStudent()
      } else {
        this.filter.class_group_id = await evt;
        //("ok", this.filter.class_group_id);
        this.$root.$emit("filterByClassGroup", this.filter);
        this.optionsName = []
        this.getMyStudent()
      }
    },
    filterByMonth(evt) {
      //(evt);
      this.filter.month = evt;
      this.filter.start_periode = this.months[0].date;
      this.$root.$emit("filterPresenceByMonth", this.filter);
    },
    filterReset(){
      this.search = null
      this.filter.school_year_id = ''
      this.filter.now = new Date().toISOString().substr(0, 10)
      this.month = new Date().toISOString().substr(0, 4) + "-0" + (new Date().getMonth() + 1)
      this.start_periode = ''
      this.class_group_id = ''
      this.$root.$emit("filterReset", this.filter);
    },
    // absen ruang kelas
    async getClassroomOption() {
      let response = await module.setTreeSelect(`api/classrooms/my-classrooms/${getUser().id}`);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.classrooms = response.data;
        this.classrooms.unshift({
          label: "Pilih Ruang Kelas",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getParticipants(id) {
      let filterParams = ``;
      let response = await module.paginate(
        `api/classrooms/${this.filter.classroom_id}/participants`,
        `?page=1&page_size=40${filterParams}`
      );

    //   let a, b;
    //   b = 40 * (1 - 1) + 1;
    //   for (a = 0; a < response.data.length; a++) {
    //     response.data[a].number = b + a;
    //   }

      this.participants = response.data;
      //("refresh participants", this.participants);
      //("refresh participants 2", response.data);

// let filterParams = `&class_group_id=${this.detail.id}`;
//       // classrooms/{classroomId}/participants
//       let response = await module.paginate(
//         `class-groups/my-students/users/${getUser().id}`,
//         `?page=1&page_size=40${filterParams}`
//       );
//       // let pagination = response.meta.pagination;
//       // this.totalRows = pagination.total;

//       this.participants = response.data;
//       //("refresh participants", this.participants);

    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kelompok Kelas", route: "/classrooms" },
      { title: "Detail Kelompok Kelas" },
    ]);

    this.getClassGroupById();
    this.getSChoolYearActive();
    this.getClassGroupOption();
    this.getMyStudent();
    // this.getParticipants();
    this.getClassroomOption();

    this.$root.$on("filterPresenceByClassroom", (filter) => {
      //("filter", filter)
      this.filter.classroom_id = filter.classroom_id
      this.getParticipants()
    });
  },
};
</script>

<style scoped>
.image-input-wrapper {
  width: 40px !important;
  height: 40px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>